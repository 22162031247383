import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import HeroTitle from '../../components/HeroTitle';
import BoldTitle from '../../components/BoldTitle';
import searchIconPrimary from '../../icons/search-color-primary.svg';
import algoliasearch from 'algoliasearch';
import algoliaImage from '../../../static/images/search-by-algolia-light-background.png';
import { CommonContext } from '../../context';

export const query = graphql`
  query allQuestionsQuery {
    categories: allWpFaq {
      group(field: faq___category) {
        fieldValue
      }
    }
    allWpFaq {
      group(field: faq___category) {
        nodes {
          id
          content
          title
          slug
          faq {
            category
          }
        }
        fieldValue
      }
    }
  }
`;

function search(query) {
  const client = algoliasearch(
    'BYMMFR7J73',
    '673b724783fc55d997403fc3debb67dd',
  );
  const index = client.initIndex('gtaf_website_pages');

  // only query string
  return index.search(query, {
    tagFilters: 'FAQ',
  });
}

function SupportPage({ data, location }) {
  const [searchHits, setSearchHits] = useState(null);
  const [timer, setTimer] = useState(null);

  const getCategoryQAs = (category) => {
    return (
      data.allWpFaq.group.find((item) => item.fieldValue === category) || []
    );
  };

  const renderSearch = () => (
    <div className="relative py-7 bg-gradient-to-b from-primary-1 to-primary-2">
      <div className="flex items-start justify-center px-6 h-80">
        <div className="w-full max-w-2xl">
          <HeroTitle
            className="mx-auto mt-12 md:text-center"
            text="How Can We Help?"
          />
          <div className="w-full px-4 py-5 mt-8 rounded-lg shadow-2xl bg-neutral">
            <div className="flex">
              <img src={searchIconPrimary} />
              <input
                type="search"
                placeholder="Search"
                className="w-full border-transparent focus:border-transparent focus:ring-0 font-regular placeholder-neutral-5"
                onChange={(e) => {
                  let q = e.target.value;
                  if (q.trim().length == 0) {
                    setSearchHits(null);
                  } else {
                    if (timer != null) {
                      clearTimeout(timer);
                    }
                    setTimer(
                      setTimeout(() => {
                        search(q).then(({ hits }) => {
                          setSearchHits(hits);
                        });
                      }, 500),
                    );
                  }
                }}
              />
            </div>
            {searchHits && (
              <div>
                <div className="overflow-y-auto max-h-72">
                  {searchHits.length == 0 && (
                    <div className="my-4 support-query-item text-accent-2">
                      No items found
                    </div>
                  )}
                  {searchHits.map((item) => (
                    <div
                      key={item.objectID}
                      className="my-4 support-query-item text-accent-2"
                    >
                      <Link
                        className="link-underline-accent"
                        to={`/support/${item.slug}`}
                        dangerouslySetInnerHTML={{
                          __html: item._highlightResult.title.value,
                        }}
                      ></Link>
                    </div>
                  ))}
                </div>

                <img
                  src={algoliaImage}
                  className="h-4 mt-2 ml-auto"
                  alt="Algolia search"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderItemCard = (item) => {
    const categoryQAs = getCategoryQAs(item.fieldValue);
    return (
      <div
        className="p-6 pr-16 my-8 shadow-xl font-regular rounded-2xl md:my-0"
        key={item.fieldValue}
      >
        <div className="mb-6 text-xl md:text-3xl">{item.fieldValue}</div>
        <ul className="overflow-hidden h-72">
          {categoryQAs.nodes.map((questionItem) => (
            <li key={questionItem.title} className="my-4 text-accent-2">
              <Link
                className="link-underline-accent"
                to={`/support/${questionItem.slug}`}
              >
                {questionItem.title}
              </Link>
            </li>
          ))}
        </ul>
        <br />
        <div className="text-accent-2">
          <Link
            className="link-underline-accent"
            to={'/support/' + item.fieldValue.toLowerCase() + '/'}
          >
            More
          </Link>
        </div>
      </div>
    );
  };

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        title="Help & Support"
        keywords={[
          'support',
          'faq',
          'quran',
          'hadith',
          'islam',
          'greentech',
          'greentechapps',
        ]}
        location={location}
      >
        {renderSearch()}
        <div className="flex justify-center pb-20 bg-neutral-1">
          <div className="w-full max-w-6xl px-6">
            <div className="mt-24 mb-9 md:mb-8">
              <BoldTitle className="md:text-center">
                Browse All Topics
              </BoldTitle>
            </div>
            <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8">
              {data.categories.group.map((item) => renderItemCard(item))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center bg-neutral">
          <div className="w-full max-w-6xl px-6 my-20">
            <BoldTitle className="md:text-center">
              Can&apos;t Find What You&apos;re Looking for?
            </BoldTitle>
            <div className="my-8 text-center font-regular">
              We&apos;ll help you find the answer or connect with an agent.
            </div>
            <div className="text-center">
              <Link to="/contact">
                <button
                  className={
                    'bg-secondary w-40 rounded-full text-neutral h-12 font-regular text-sm'
                  }
                >
                  <div className="inline-block w-auto mx-auto">Contact us</div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

SupportPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default SupportPage;
